import { onBeforeUnmount, onMounted } from 'vue'
import { useAppStore } from '@/store'
import { copyToClip } from '@/utils/copy'

export function useTextSelectCode() {
  const appStore = useAppStore()
  let isStartSelect = false
  let timeOutId: any = null
  let selectText: any = ''

  // 处理选中事件
  const handleSelectEvent = () => {
    selectText = document.getSelection()?.toString()
    if (!selectText)
      return
    if (timeOutId)
      clearTimeout(timeOutId)
    timeOutId = setTimeout(() => {
      copyToClip(selectText)
      window.$message?.success('自动复制成功', { duration: 700 })
      selectText = null
    }, appStore.autoCopyLine)
  }

  // 开启自动复制
  const startAutoCopyEvent = () => {
    if (isStartSelect)
      return
    window.$message?.success('自动复制开启', { duration: 700 })
    document.addEventListener('selectionchange', handleSelectEvent)
    isStartSelect = true
  }

  // 关闭自动复制
  const endAutoCopyEvent = () => {
    window.$message?.success('自动复制关闭', { duration: 700 })
    document.removeEventListener('selectionchange', handleSelectEvent)
    isStartSelect = false
  }

  onMounted(() => {
    if (appStore.isOpenAutoCopy)
      startAutoCopyEvent()
    window.$VueBus.on('startAutoCopyEvent', startAutoCopyEvent)
    window.$VueBus.on('endAutoCopyEvent', endAutoCopyEvent)
  })

  onBeforeUnmount(() => {
    window.$VueBus.off('startAutoCopyEvent')
    window.$VueBus.off('endAutoCopyEvent')
  })
}
