import type { ModelListType } from '@/types/pinia-type'
export interface ModelConfigListType {
  value: ModelListType
  label: string
  desc: string
  author: number
  tip?: string
  isHidden?: boolean
  isNotClick?: boolean
  maxTokens: number
}

export const OrdinaryChatAuthor = 0b1000000 // 普通会话
export const TaskChatAuthor = 0b0100000 // 任务会话
export const StandbyAuthor = 0b0010000 // 备用模型
export const NotExperimentAuthor = 0b0001000 // 非VIP免费
export const ConclusionAuthor = 0b0000100 // 结论
export const UploadFileByLink = 0b0000010 // 支持文件上传
export const IsMentionGpts = 0b0000001 // 是否可以提及gpts

export const defaultGptsModel = 'YHHCHAT4_GPTS'
export const defaultSessionModel = 'YHHChat4o_mini'
export const defaultConclusionModel = 'YHHCHAT4_ALL_TOOLS'
// 1(普通), 2(任务), 3(备用), 4(非VIP免费), 5(结论)，6(通过链接上传文件), 7(是否可以提及gpts)

export const modelConfigList: Array<ModelConfigListType> = [
  {
    value: 'YHHCHAT4_ALL_TOOLS',
    label: '4.0-全功能版-32k（最推荐）',
    desc: '（25积分/条）',
    tip: '（高质量但是有时不稳定）支持上传文件、理解图片、联网搜索、绘制图片、运行python代码、@GPTs。',
    author: OrdinaryChatAuthor | TaskChatAuthor | ConclusionAuthor | UploadFileByLink | IsMentionGpts,
    maxTokens: 32 * 1024,
  },
  {
    value: 'YHHCHAT4_4o',
    label: '4.0-4o-128k',
    desc: '（按tokens计费，2.5倍率）',
    tip: '（有时可能不稳定）拥有超长上下文窗口，可以一次性处理128k tokens。Token（令牌，简写T）是AI用于计算使用量的单位。一个英文单词约等于1个Token，一个中文字约等于1.3个Token。4.0-4o所消耗的Token为2.5倍。40积分（0.4元）可抵扣10000 tokens，提问消耗的Tokens是回答消耗Tokens的一半。',
    author: OrdinaryChatAuthor | TaskChatAuthor | StandbyAuthor | NotExperimentAuthor | ConclusionAuthor,
    maxTokens: 128 * 1024,
  },
  {
    value: 'YHHChat4o_mini',
    label: '4o-mini-8k',
    desc: '（免费使用）',
    tip: '（准确性较低）是我们提供的初级模型，生成速度快但是准确度较低，如果您需要较高的准确度请使用4.0全功能模型。',
    author: OrdinaryChatAuthor | NotExperimentAuthor,
    maxTokens: 2 * 1024,
  },
]

export const isUploadFile = (key: string) => {
  if (key === defaultGptsModel)
    return true
  const model = modelConfigList.find(item => item.value === key)
  if (model)
    return model.author & UploadFileByLink
  return false
}

export function getModelConfigList(author: number) {
  return modelConfigList.filter(item => item.author & author)
}
