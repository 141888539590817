import { defineStore } from 'pinia'
import { delFavoriteGizmos, getFavoriteGizmos, putFavoriteGizmos } from '@/api/gpts'
const LOCAL_NAME = 'gptsStorage'
interface StateType {
  gptsList: Array<Partial<Chat.GptsParamsType>>
}
const defaultState = (): StateType => {
  return {
    gptsList: [],

  }
}

export const useGptsStore = defineStore('gpts-store', {
  state: (): StateType => {
    return defaultState()
  },

  getters: {},

  actions: {
    // ============== start gpts ===================
    async loadGptsList() {
      const { list } = await getFavoriteGizmos({
        sort: {
          updateAt: 1,
        },
      })
      this.gptsList = list || []
    },
    async addGptsModel(gpts: Partial<Chat.GptsParamsType>) {
      if (!gpts.id) {
        window.$message?.warning('gpts的id不能为空')
        throw new Error('ID异常')
      }
      const { _id } = await putFavoriteGizmos(gpts)
      const index = this.gptsList.findIndex(item => item.id === gpts.id)
      if (index === -1)
        this.gptsList.push({ ...gpts, _id })
      else
        this.gptsList.push(Object.assign(this.gptsList[index], { ...gpts, _id }))
    },
    async removeGpts(id: string) {
      const index = this.gptsList.findIndex(item => item.id === id)
      const _id = this.gptsList[index]?._id
      if (!_id) {
        window.$message?.warning('gpts的id不存在')
        throw new Error('ID异常')
      }
      await delFavoriteGizmos(_id)
      if (index !== -1)
        this.gptsList.splice(index, 1)
    },
    // ============== end gpts =====================
  },
})
