import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import request from './axios'
import type { ResponseType } from '@/types/common'
function successHandler<T>(res: AxiosResponse<ResponseType<T>>) {
  const { data, message } = res.data
  if (data === null || data === undefined)
    return Promise.resolve(message as T)
  return Promise.resolve(data as T)
}
function failHandler<T = any>(error: ResponseType<T>) {
  return Promise.reject(error)
}

export default {
  get<T>(url: string, config?: AxiosRequestConfig) {
    return request.get(url, config).then(successHandler<T>, failHandler<Error>)
  },
  post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return request.post(url, data, config).then(successHandler<T>, failHandler<Error>)
  },
  put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return request.put(url, data, config).then(successHandler<T>, failHandler<Error>)
  },
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return request.patch(url, data, config).then(successHandler<T>, failHandler<Error>)
  },
  delete<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return request.delete(url, { data, ...config }).then(successHandler<T>, failHandler<Error>)
  },
}
