<script lang="ts" setup>
import { onMounted, reactive, ref, toRefs } from 'vue'
import type { FormInst, FormRules } from 'naive-ui'
import { useRoute } from 'vue-router'
import { NButton, NForm, NFormItem, NInput, NModal, NSpace } from 'naive-ui'
import { useSmsCode } from '@/views/login/hooks/use-sms-code'
import { formRules, getConfirmPwdRule } from '@/views/login/utils/rule'
import { loginUser, registerUser, requestSignUpCaptcha } from '@/api/author'
// import { countryOptions } from '@/config/country-options'
import { useAppStore, useUserStore } from '@/store'
const emits = defineEmits(['updateRouter'])
const userStore = useUserStore()
const appStore = useAppStore()
const { label, isCounting, loading: smsLoading, start, stop } = useSmsCode()
const route = useRoute()
const formRef = ref<HTMLElement & FormInst>()
const model = reactive({
  username: '',
  password: '',
  email: '',
  code: '',
  confirmPwd: '',
  inviter: '',
  country: null,
})
const rules: FormRules = {
  email: formRules.email,
  code: formRules.code,
  password: formRules.pwd,
  confirmPwd: getConfirmPwdRule(toRefs(model).password),
  country: formRules.country,
}

const isShowModal = ref(false)

async function handleSmsCode() {
  try {
    if (!model.email) {
      window.$message?.warning('请输入电子邮箱!')
      return
    }
    start()
    const message = await requestSignUpCaptcha(model.email)
    window.$message?.success(message ?? '发送成功')
  }
  catch (_error) {
    setTimeout(() => stop(), 1000)
  }
}
const isDisabled = ref(false)
const isRegisterSuccess = ref(false)
async function handleSubmit() {
  try {
    isDisabled.value = true
    window.$loadingBar?.start()
    await formRef.value?.validate()
    // if (model.country === 'China') {
    //   window.$message?.warning('很抱歉！我们暂未向该国家/地区开放服务！')
    //   window.$loadingBar?.error()
    //   return
    // }
    const user = Object.assign({}, model)
    const options = { captcha: model.code }
    await registerUser({ user, options })
    const userRes = await loginUser({
      username: model.email,
      password: model.password,
      rememberMe: true,
    })
    userStore.setToken(userRes?.token)
    userStore.handleAndSaveUser(userRes)
    window.$message?.success('注册成功!')
    isShowModal.value = true
    isRegisterSuccess.value = true
    window.$loadingBar?.finish()
  }
  catch (error: any) {
    window.$loadingBar?.error()
  }
  finally {
    isDisabled.value = false
  }
}

function reloadLogin() {
  window.location.reload()
}

onMounted(() => {
  if (route.query && route.query.id) {
    model.inviter = route.query.id as string
    appStore.setInviteredUser(model.inviter)
  }
  else if (appStore.inviteredUser) {
    model.inviter = appStore.inviteredUser
    const href = window.location.href
    if (href.includes('?'))
      window.location.href += `&id=${model.inviter}`
    else
      window.location.href += `?id=${model.inviter}`
  }
})
</script>

<template>
  <NForm ref="formRef" :model="model" :rules="rules" size="large" :show-label="false">
    <NFormItem path="username">
      <NInput v-model:value="model.username" placeholder="用户名" />
    </NFormItem>
    <NFormItem path="email">
      <NInput v-model:value="model.email" placeholder="电子邮箱" />
    </NFormItem>
    <NFormItem path="code">
      <div class="flex items-center w-full">
        <NInput v-model:value="model.code" placeholder="验证码" />
        <div class="w-[18px]" />
        <NButton size="large" :disabled="isCounting" :loading="smsLoading" @click="handleSmsCode">
          {{ label }}
        </NButton>
      </div>
      <div class="absolute right-[6px] bottom-[-22px] cursor-pointer hover:text-[#009dff] text-[#0073ff]" @click="isShowModal = true">
        收不到验证码?
      </div>
    </NFormItem>
    <NFormItem path="password">
      <NInput v-model:value="model.password" type="password" show-password-on="click" placeholder="密码" />
    </NFormItem>
    <NFormItem path="confirmPwd">
      <NInput v-model:value="model.confirmPwd" type="password" show-password-on="click" placeholder="确认密码" />
    </NFormItem>
    <!-- <NFormItem path="country">
      <NSelect v-model:value="model.country" filterable :options="countryOptions" placeholder="国家" />
    </NFormItem> -->
    <!-- <NFormItem path="inviter">
      <NInput v-model:value="model.inviter" placeholder="邀请码(可选)" />
    </NFormItem> -->
    <NSpace :vertical="true" :size="18">
      <NButton type="primary" size="large" :block="true" :round="true" :disabled="isDisabled" @click="handleSubmit">
        确定
      </NButton>
      <NButton size="large" :block="true" :round="true" @click="emits('updateRouter', 'pwd-login')">
        登录
      </NButton>
    </NSpace>
    <NModal v-model:show="isShowModal" preset="card" class="!w-[380px]" @close="isShowModal = false, isRegisterSuccess = false">
      <div>
        <img src="/images/customer-service.png" alt="背景" class="w-[300px] h-[400px] m-auto mb-2">
        <div v-if="isRegisterSuccess" class="w-full text-center">
          添加客服微信免费领取1天体验会员!
        </div>
        <div v-else class="w-full text-center">
          打开微信扫一扫，添加微信客服
        </div>
      </div>
      <NButton v-if="isRegisterSuccess" size="large" :block="true" :round="true" @click="reloadLogin">
        直接登录
      </NButton>
    </NModal>
  </NForm>
</template>
