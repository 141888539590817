<script setup lang="ts">
import { onMounted, ref } from 'vue'
const homeRef = ref<HTMLElement>()
const isScroll = ref(false)
onMounted(() => {
})
</script>

<template>
  <div ref="homeRef" class="home-contain">
    <div class="home-card-contain" />

    <div class="home-card-contain" />
    <div class="home-card-contain" />
  </div>
</template>

<style lang="less" scoped>
.home-contain {
  width: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  height: 100vh;
}

.home-card-contain {
  scroll-snap-stop: always;
  scroll-snap-align: end;
  width: 100%;
  height: 100%;
  background: url('./images/breed-service-bg.png') 0 0 no-repeat, linear-gradient(45deg, #3b83ee 0%, #fff 99%);
  box-sizing: border-box;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
