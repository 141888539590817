<script setup lang="ts">
import { computed } from 'vue'
import type { Component } from 'vue'
import { NCard, NGradientText } from 'naive-ui'
import { useRoute, useRouter } from 'vue-router'
import { CodeLogin, LoginBg, PwdLogin, Register, ResetPwd } from './components'
import { CmpFixedTime } from '@/components/common'
const router = useRouter()
const route = useRoute()
const APPNAME = import.meta.env.VITE_APP_NAME
type LoginKeyType = 'pwd-login' | 'code-login' | 'register' | 'reset-pwd'
interface LoginModule {
  key: LoginKeyType
  label: string
  component: Component
}

const modules: LoginModule[] = [
  { key: 'pwd-login', label: '登录', component: PwdLogin },
  { key: 'code-login', label: '手机验证码登录', component: CodeLogin },
  { key: 'register', label: '注册', component: Register },
  { key: 'reset-pwd', label: '重置密码', component: ResetPwd },
]
const loginKey = computed(() => {
  return route.params?.state as string
})
const updateRouter = (key: LoginKeyType) => {
  router.push({ name: 'login', params: { state: key } })
}
const activeModule = computed(() => {
  const active: LoginModule = { ...modules[0] }
  const findItem = modules.find(item => item.key === loginKey.value)
  if (findItem)
    Object.assign(active, findItem)

  return active
})
</script>

<template>
  <div class="relative flex justify-center items-center w-full h-full bg-[#a9c6ff] overflow-y-auto">
    <NCard size="large" class="z-40 !w-auto !rounded-[20px] shadow-sm bg-[#fff] md:mt-0">
      <div class="w-[80vw] sm:w-[360px]">
        <header class="flex flex-col items-stretch justify-center space-y-2">
          <div class="flex justify-start">
            <NGradientText :style="{ display: 'block', fontWeight: 'bold' }" type="primary" :size="32">
              欢迎使用 {{ APPNAME }}
            </NGradientText>
          </div>
          <div class="flex justify-end">
            <NGradientText :style="{ display: 'block' }" type="primary" :size="20">
              —— 低价、快速、稳定、强大
            </NGradientText>
          </div>
        </header>

        <main class="pt-[24px]">
          <h3 class="text-[18px] text-sky-400">
            {{ activeModule.label }}
          </h3>
          <div class="pt-[24px]">
            <component :is="activeModule.component" @update-router="updateRouter" />
          </div>
        </main>
      </div>
    </NCard>
    <LoginBg theme-color="#a9c6ff" />
    <CmpFixedTime style="position: fixed; left: 50%; bottom: 32px;;transform: translateX(-50%);z-index: 10;" target-time="2023-9-11 00:00:00" />
  </div>
</template>
