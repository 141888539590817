import { defineStore } from 'pinia'
import ObjectID from 'bson-objectid'
import { ss } from '@/utils/storage'
import { convertLinkListToChart, deepHandleNode, deepSearchNode, deepSearchNodes, defaultModel } from '@/utils/common'
import { router } from '@/router'
import { getDialogContain } from '@/utils/naive-collect-util'
import { addChatHistory, clearChatBodyById, getChatBodyById, getChatHistoryList, saveChatBatch } from '@/api/chat'
import { fetchChatAPIProcess } from '@/api'
import type { ChatAPIParamsType } from '@/types/pinia-type'
const openLongReply = import.meta.env.VITE_GLOB_OPEN_LONG_REPLY === 'true'

const LOCAL_NAME = 'chatTaskStorage'
const JSONParseList = ['uuid', 'title', 'isInit', 'model', 'inputText', 'inputTokens', 'backgroundText', 'backgroundTokens', 'targetText', 'targetTokens', 'demandText', 'demandTokens', 'conclusionText', 'conclusionTokens', 'abstractText', 'abstractTokens', 'quotoList', 'isResult']
const defaultHistory = (uuid: string, dModel?: string): Chat.TaskHistory => ({
  type: 'taskChat',
  uuid,
  title: 'New Task',
  isEdit: false,
  model: defaultModel(dModel),
  isInit: false,
  inputText: '',
  inputTokens: 0,
  backgroundText: '',
  backgroundTokens: 0,
  targetText: '',
  targetTokens: 0,
  demandText: '',
  demandTokens: 0,
  conclusionText: '',
  conclusionTokens: 0,
  abstractText: '',
  abstractTokens: 0,
  quotoList: [],
  children: [],
  isChildren: true,
  isUpdate: true,
  oldSize: '',
})

const defaultState = (): Chat.ChatState<Chat.TaskHistory> => {
  return {
    active: null,
    version: 0,
    loadParams: {
      limit: 10,
      page: 1,
      total: 0,
    },
    history: [],
    chat: [],
  }
}
export const useChatTaskStore = defineStore('chat-task-store', {
  state: (): Chat.ChatState<Chat.TaskHistory> => {
    const localState = ss.get(LOCAL_NAME)
    return { ...defaultState(), ...localState }
  },

  getters: {
    getChatHistoryByCurrentActive(state: Chat.ChatState<Chat.TaskHistory>) {
      return { ...deepSearchNode(state.active as string, state.history) }
    },

    getChatByCurrentActive(state: Chat.ChatState<Chat.TaskHistory>) {
      return { ...state.chat.find(item => item.uuid === state.active) }
    },

    getChatByActiveUuid(state: Chat.ChatState<Chat.TaskHistory>) {
      return state.chat.find(item => item.uuid === state.active)?.data ?? []
    },

    getChatByUuid(state: Chat.ChatState<Chat.TaskHistory>) {
      return (uuid?: string) => {
        if (uuid)
          return state.chat.find(item => item.uuid === uuid)?.data ?? []
        return state.chat.find(item => item.uuid === state.active)?.data ?? []
      }
    },
  },

  actions: {
    // 获取远程输入
    async getHistoryList() {
      const tempHistory = await getChatHistoryList({ chatType: 'taskChat' })
      if (tempHistory.version !== this.version) {
        const tip = '云端数据的版本与本地版本不一致, 请确定是否需要覆盖本地的版本?'
        await getDialogContain('数据版本不一致', tip)
        this.version = tempHistory.version
      }
      this.history = deepHandleNode(tempHistory.data, (his) => {
        his.oldSize = JSON.stringify(his, JSONParseList) ?? ''
        his.isUpdate = false
        return his
      })

      this.version = tempHistory.version
      const uuid = this.history[0]?.uuid ?? ''
      this.setActive(uuid)
      this.recordState()
    },

    // 保存本地历史记录
    async saveHistoryRemote() {
      try {
        const chatHistorys: Array<any> = []
        const positions = deepHandleNode(this.history, (his) => {
          if (his.isUpdate) {
            const tempHis = JSON.parse(JSON.stringify(his, JSONParseList))
            tempHis.isChildren = !his.children?.length
            tempHis._id = his.uuid
            chatHistorys.push(tempHis)
          }
          return { _id: his.uuid }
        })
        const chatBatchRes = await saveChatBatch({ chatHistorys, positions, version: this.version })
        deepHandleNode(this.history, (his) => {
          his.oldSize = JSON.stringify(his, JSONParseList) ?? ''
          his.isUpdate = false
        })
        let isError = false
        chatBatchRes?.unsaveChatHistorys?.forEach((his) => {
          window.$message?.error(`${his.title} 会话未保存，原因：会话不存在!`)
          isError = true
        })
        this.version++
        this.recordState()
        if (isError)
          throw new Error('保存失败')
      }
      catch (error: any) {
        if (error.code !== 505)
          return
        await getDialogContain('数据版本不一致', error?.message)
        this.version = error?.data.newVersion
        await this.saveHistoryRemote()
      }
    },

    // 创建会话
    async createHistory(dModel?: string, parentId?: string, parentPosition: 'moveToChatBehind' | 'moveToChildren' = 'moveToChildren') {
      const uuid = new ObjectID().toString()
      const tempHistory: any = defaultHistory(uuid, dModel)
      tempHistory._id = uuid
      if (parentId) {
        tempHistory.operation = {
          [parentPosition]: parentId,
        }
      }
      await addChatHistory(tempHistory)
      if (parentId) {
        const { nodes, index } = deepSearchNodes(parentId, this.history)
        if (nodes !== undefined && index !== undefined) {
          if (parentPosition === 'moveToChildren') {
            if (!nodes[index]?.children)
              nodes[index].children = []
            nodes[index]?.children?.unshift(tempHistory)
          }
          else if (parentPosition === 'moveToChatBehind') {
            nodes.splice(index + 1, 0, tempHistory)
          }
        }
        else {
          window.$message?.error('会话异常，无法基于当前节点创建新会话')
        }
      }
      else {
        this.history.unshift(tempHistory)
      }
      this.history = [...this.history]
      this.chat.unshift({ uuid, data: [], allTokens: 0 })
      await this.setActive(uuid)
    },

    // 清空会话
    async clearChatByUuid(uuid: string) {
      if (!uuid) {
        window.$message?.error('会话异常')
        return
      }
      await clearChatBodyById(uuid)
      const charIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (charIndex !== -1) {
        this.chat[charIndex].data = []
        this.recordState()
      }
      window.$message?.success('清除成功')
      this.updateHistory(uuid, { isInit: false })
    },

    // ========================== 聊天记录 ==============================
    async fetchChatAPIOnce({ uuid, gizmo, index, message, selectMode, options, lastText, controller, scrollBottomFunc, attachments, isRegenerate }: ChatAPIParamsType) {
      message = convertLinkListToChart(message, attachments)
      await fetchChatAPIProcess<Chat.ConversationResponse>({
        prompt: message,
        model: selectMode,
        gizmo,
        conversationId: uuid,
        isCloudStorage: true,
        options,
        signal: controller.signal,
        onDownloadProgress: ({ event }) => {
          const xhr = event.target
          const { responseText } = xhr
          const lastIndex = responseText.lastIndexOf('\n', responseText.length - 2)
          let chunk = responseText
          if (lastIndex !== -1)
            chunk = responseText.substring(lastIndex)
          const data = JSON.parse(chunk)
          this.updateChatByUuid(
            uuid,
            index,
            {
              dateTime: new Date().toLocaleString(),
              text: lastText + (data.text ?? (data.message ?? '')),
              inversion: false,
              error: !!data.message,
              gizmo,
              loading: true,
              conversationOptions: { conversationId: data.conversationId, parentMessageId: data.id },
              requestOptions: { prompt: message, options: { ...options } },
            },
          )
          if (openLongReply && data.detail?.choices[0]?.finish_reason === 'length') {
            options.parentMessageId = data.id
            lastText = data.text
            message = ''
            return this.fetchChatAPIOnce({ uuid, index, message, selectMode, options, lastText, controller, scrollBottomFunc, attachments, isRegenerate })
          }
          scrollBottomFunc && scrollBottomFunc()
        },
        isRegenerate,
      })
      this.updateChatSomeByUuid(uuid, index, { loading: false })
    },
    // ========================== end 聊天记录 ==============================

    updateHistory(uuid: string, edit: Partial<Chat.TaskHistory>) {
      const node = deepSearchNode(uuid, this.history)
      if (!node)
        return
      Object.assign(node, edit)
      const isUpdate = !node.oldSize || node.oldSize !== JSON.stringify(node, JSONParseList)
      Object.assign(node, { isUpdate })
      this.recordState()
    },

    setFullUpdateHistory(history: Array<Chat.TaskHistory>) {
      this.history = history
      this.recordState()
    },

    async deleteHistory(uuid: string) {
      const chatIndex = this.chat.findIndex(itemChat => itemChat.uuid === uuid)
      if (chatIndex !== -1)
        this.chat.splice(chatIndex, 1)
      let { nodes, index } = deepSearchNodes(uuid, this.history)
      if (nodes !== undefined && index !== undefined)
        nodes.splice(index, 1)
      if (Number(index) >= this.history.length)
        index = this.history.length - 1
      const nextUuid = this.history[index ?? 0]?.uuid ?? ''
      this.history = [...this.history]
      await this.setActive(nextUuid)
    },

    async setActive(uuid: string) {
      this.active = uuid
      await this.reloadRoute(uuid)
    },

    getChatByUuidAndIndex(uuid: string, index: number) {
      if (!uuid) {
        if (this.chat.length)
          return this.chat[0].data[index]
        return null
      }
      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1)
        return this.chat[chatIndex].data[index]
      return null
    },

    async createChatByUuid(uuid: string, message: string, options: Chat.ConversationRequest | null, attachments?: Array<any>) {
      message = convertLinkListToChart(message, attachments)
      const newChat: Chat.Chat = {
        dateTime: new Date().toLocaleString(),
        text: message,
        inversion: true,
        error: false,
        conversationOptions: null,
        requestOptions: { prompt: message, options: { ...options } },
      }
      if (options) {
        newChat.inversion = false
        newChat.loading = true
      }
      this.addChatByUuid(uuid, newChat)
    },

    async addChatByUuid(uuid: string, chat: Partial<Chat.Chat>) {
      const createChat = Object.assign({
        dateTime: new Date().toLocaleString(),
        text: '',
        inversion: false,
        error: false,
        conversationOptions: null,
        requestOptions: { prompt: '', options: null },
      }, chat)
      let chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex === -1) {
        this.chat.unshift({ uuid, data: [], allTokens: 0 })
        chatIndex = 0
      }
      const node = deepSearchNode(uuid, this.history)
      if (!node)
        return

      this.chat[chatIndex].data.push(createChat)
      if (node.title === 'New Task' && node.isInit) {
        node.title = chat?.text ?? ''
        node.isUpdate = true
      }
      if (!node.isInit) {
        node.isInit = true
        node.isUpdate = true
      }
      this.recordState()
    },

    updateChatByUuid(uuid: string, index: number, chat: Chat.Chat) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data[index] = chat
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data[index] = chat
        this.recordState()
      }
    },

    // 移除指定会话某index之后的聊天记录
    removeChatAfterByUuid(uuid: string, index: number) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data.splice(index + 1, this.chat[0].data.length - index)
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data.splice(index + 1, this.chat[chatIndex].data.length - index)
        this.recordState()
      }
    },

    async updateAllChatByUuid(uuid: string) {
      if (!uuid)
        return
      try {
        const chatBody = await getChatBodyById(uuid)
        const chatIndex = this.chat.findIndex(c => c.uuid === uuid)
        if (chatIndex !== -1)
          this.chat.splice(chatIndex, 1, chatBody)
        else
          this.chat.push(chatBody)
      }
      catch (error: any) {
        const { code } = error ?? {}
        if (code === 401)
          this.setActive('')
      }
    },

    updateChatSomeByUuid(uuid: string, index: number, chat: Partial<Chat.Chat>) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data[index] = { ...this.chat[0].data[index], ...chat }
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data[index] = { ...this.chat[chatIndex].data[index], ...chat }
        this.recordState()
      }
    },

    deleteChatByUuid(uuid: string, index: number) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data.splice(index, 1)
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data.splice(index, 1)
        this.recordState()
      }
    },

    async reloadRoute(uuid?: string) {
      this.recordState()
      const activeUuid = router.currentRoute.value.params?.uuid
      const activeName = router.currentRoute.value.name
      if (activeUuid !== uuid && activeName === 'task-chat')
        router.replace({ name: 'task-chat', params: { uuid } })
    },

    recordState() {
      ss.set(LOCAL_NAME, this.$state)
    },
  },
})
