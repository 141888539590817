import type { AxiosProgressEvent, GenericAbortSignal } from 'axios'
import service from '@/api/request/service'
import { store, useAppStore, useUserStore } from '@/store'

export default function fetchChatConfig<T = any>() {
  return service.post<T>('/chatgpt/config')
}

export function fetchChatAPIProcess<T = any>(
  params: {
    prompt: string
    model: string
    gizmo?: any
    conversationId: string
    isCloudStorage: boolean
    chatPurpose?: 'abstract' | 'conclusion'
    options?: { conversationId?: string; parentMessageId?: string }
    signal?: GenericAbortSignal
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
    isRegenerate?: boolean },
) {
  const data: Record<string, any> = {
    prompt: params.prompt,
    options: params.options,
    myOptions: {
      model: params.model,
      // 使用用户设置里的预设指令
      systemMes: useUserStore().userInfo.presetsDirect,
      isRegenerate: !!params.isRegenerate,
      isCloudStorage: !!params.isCloudStorage,
      chatHistory: {
        _id: params.conversationId,
      },
      overLimitAutoExpense: useAppStore(store).isOverLimitAutoExpense,
    },
  }
  if (params?.gizmo)
    data.myOptions.gizmo = params?.gizmo
  const url = '/chatgpt/chat-process'
  // if (import.meta.env.VITE_ENV === 'test')
  //   url = `https://test.yhhchat.top/api${url}`
  // else if (import.meta.env.VITE_ENV === 'prod')
  //   url = useAppStore(store).isOpenSpare ? `https://chat.yhhit.xyz/api${url}` : `https://yhhchat.top/api${url}`

  return service.post(url, data, {
    signal: params.signal,
    onDownloadProgress: params.onDownloadProgress,
  })
}

export function chatgptUpload(conversationId: string, file: any, onProgress?: Function) {
  const formData = new FormData()
  formData.append('files', file)
  return service.post<any>('/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: ({ total, loaded }: any) => {
      const percent = (loaded / total * 100) | 0
      onProgress && onProgress({ percent })
    },
  })
}

export function chatgptDownload(conversationId: string, asset_pointer: string, isUseCDN: boolean) {
  return service.get<any>(`/chatgpt/download?conversationId=${conversationId}&asset_pointer=${asset_pointer}&isUseCDN=${isUseCDN}`)
}
