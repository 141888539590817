import type { CancelTokenSource } from 'axios'
import service from '@/api/request/service'
import type { MembershipGroupType, MembershipInfoType, PointRecordsType, PointsType, RechargeType, UserOrdersType, UserStoreType, UserType } from '@/types/user'

export function networkPing() {
  return service.get<string>('/ping')
}

export function loginUser(data: Partial<UserType> = {}) {
  return service.post<Partial<UserType>>('/login', data)
}

export function requestPasswordReset(email: string) {
  return service.post<string>('/request-password-reset', { email })
}

export function requestSignUpCaptcha(email: string) {
  return service.post<string>('/request-sign-up-captcha', { email })
}

export function resetPassword(email: string, code: string, password: string) {
  return service.post<string>('/reset-password', { email, code, password })
}

export function userModifyPassword(data: any = {}) {
  return service.patch<string>('/user/password', data)
}

export function registerUser(data: any = {}) {
  return service.post<Partial<UserType>>('/user', data)
}

export function getUser() {
  return service.get<Partial<UserStoreType>>('/user')
}

export function modifyUser(data: { appSetting?: any; username?: string } = {}) {
  return service.patch<Partial<UserType>>('/user', data)
}

export function userCheckIn() {
  return service.post<string>('/user/check-in')
}

export function userIsCheckIn() {
  return service.get<{ checkInStatus: boolean }>('/user/is-check-in')
}

// 获取积分总数信息
export function getUserPoints() {
  return service.get<PointsType>('/user/points')
}

// 获取积分列表信息
export function getPointRecords(data: any = {}) {
  return service.post<{ list: Array<PointRecordsType>; totalCount: number }>('/user/point-records', data)
}

// 查询用户会员组
export function getMemberInfo() {
  return service.get<Partial<MembershipGroupType>>('/user/membership')
}

// 查询用户组价格列表
export function getUnitPricesList(data: any = {}) {
  return service.post<Array<any>>('/user/membership/unit-prices-estimation', data)
}
export function getMembershipInfo() {
  return service.get<Partial<MembershipInfoType>>('/user/membership-info')
}

// 查询用户会员组
export function getMemberActivate(data: any) {
  return service.post<any>('/user/membership/activate', data)
}
// 生成支付订单
export function getUserRecharge(totalAmount: number) {
  return service.post<Partial<RechargeType>>('/user/recharge', { totalAmount })
}
// 生成支付订单
export function getUserOrdersList(params: any) {
  return service.post<{ list: Array<UserOrdersType>; totalCount: number }>('/user/orders', params)
}
// 提现积分
export function withdrawalForUser(data: any) {
  return service.post<string>('/user/withdrawal', data)
}
// 提现记录列表
export function withdrawalsListForUser(data: any) {
  return service.post<string>('/user/withdrawals', data)
}

// 计算tokens
export function calTokens(message: string, cancelToken?: CancelTokenSource) {
  return service.post<number>('/user/cal-tokens', { str: message ?? '' }, {
    cancelToken: cancelToken?.token,
  })
}
