import { onBeforeUnmount, onMounted, ref } from 'vue'
import { windowFlush } from '@/utils/common'
import { getDialogContain } from '@/utils/naive-collect-util'
export function useVersionUpdate() {
  const isShowDialog = ref(false)

  // 处理版本提示信息
  const handleVersonDialog = async ({ gVersion }: any) => {
    if (isShowDialog.value)
      return
    try {
      isShowDialog.value = true
      await getDialogContain('发现新版本', `发现新版本${gVersion}，是否立即更新？（如果点击立即更新后没有更新，请彻底关闭程序重新打开！）`, '立即更新', '', false)
    }
    finally {
      isShowDialog.value = false
      windowFlush(true)
    }
  }

  onMounted(() => {
    window.$VueBus.on('submitVersonUpdateTip', handleVersonDialog)
  })

  onBeforeUnmount(() => {
    window.$VueBus.off('submitVersonUpdateTip')
  })
}
