import { defineStore } from 'pinia'
// import { defaultSetting, getLocalState, setLocalState } from './helper'
import { getUser } from '@/api/author'
import { ls } from '@/utils/storage'
import type { EnterpriseType, UserInfo, UserState, UserStoreType } from '@/types/pinia-type'
import type { UserStoreType as UserRequestType } from '@/types/user'
const LOCAL_NAME = 'userStorage'
const USER_TOKEN_SESSION = 'USER_TOKEN_SESSION'
const defaultSetting = (): UserState => ({
  token: undefined,
  rememberMe: false,
  user: {},
  enterprise: {},
  userInfo: {
    avatar: '',
    name: '我',
    description: '<a href="http://info.ai.yhhit.xyz" class="text-blue-500" target="_blank" > 购买次数 </a>',
  },
})
export const useUserStore = defineStore('user-store', {
  state: (): UserState => {
    const localSetting: UserState | undefined = ls.get(LOCAL_NAME)
    if (!localSetting?.rememberMe && localSetting?.token)
      localSetting.token = sessionStorage.getItem(USER_TOKEN_SESSION) ?? ''
    return { ...defaultSetting(), ...localSetting }
  },
  getters: {
    isPaidMember(state) {
      return state.user.isPaidMembershipAndNotExpired ?? false
    },
  },
  actions: {
    async requestUser() {
      const user = await getUser()
      return this.handleAndSaveUser(user)
    },

    handleAndSaveUser(user: Partial<UserRequestType>) {
      const tempObj: any = Object.assign({}, user)
      // 会员信息
      tempObj.membershipGroupId = user.membershipInfo?.membershipGroupId
      tempObj.membershipGroupName = user.membershipInfo?.membershipGroupName
      tempObj.membershipExpiration = user.membershipInfo?.membershipExpiration ?? 0
      tempObj.membershipRemainDays = user.membershipInfo?.membershipRemainDays ?? 0
      tempObj.isPaidMembershipAndNotExpired = user.membershipInfo?.isPaidMembershipAndNotExpired ?? false
      // 积分信息
      tempObj.totalAmount = user.pointInfo?.mix?.amount ?? 0
      tempObj.freeAmount = user.pointInfo?.free?.amount ?? 0
      tempObj.paidAmount = user.pointInfo?.paid?.amount ?? 0
      tempObj.referralAmount = user.pointInfo?.referral?.amount ?? 0
      tempObj.yesterdayReferralAmount = user.referralInfo?.yesterdayReferral ?? 0
      // 推广信息
      tempObj.inviteesCount = user.referralInfo?.inviteesCount ?? 0
      tempObj.yesterdayInvitees = user.referralInfo?.yesterdayInvitees ?? 0
      // 模型信息
      tempObj.YHHChat4o_mini = {
        total: user.membershipInfo?.membershipDailyUsable?.YHHChat4o_mini ?? 0,
        used: user.membershipInfo?.userDailyUsaged?.YHHChat4o_mini ?? 0,
      }
      tempObj.YHHCHAT4 = {
        total: user.membershipInfo?.membershipDailyUsable?.YHHCHAT4 ?? 0,
        used: user.membershipInfo?.userDailyUsaged?.YHHCHAT4 ?? 0,
      }

      this.updateUser(tempObj)
      // 用户预置信息
      const userSetting = user.appSetting ?? {}
      this.updateUserInfo(userSetting)

      // 企业信息
      const enterprise = user.enterpriseInfo
      this.updateEnterprise(enterprise)
    },

    setToken(token?: string) {
      this.token = token
      sessionStorage.setItem(USER_TOKEN_SESSION, token ?? '')
      this.recordState()
    },

    setRememberMe(flag: boolean) {
      this.rememberMe = flag
      this.recordState()
    },

    updateUser(user: Partial<UserStoreType>) {
      this.user = { ...this.user, ...user }
      this.recordState()
    },

    resetUser() {
      this.user = { ...defaultSetting().user }
      this.recordState()
    },

    updateUserInfo(userInfo: Partial<UserInfo>) {
      this.userInfo = { ...this.userInfo, ...userInfo }
      this.recordState()
    },

    resetUserInfo() {
      this.userInfo = { ...defaultSetting().userInfo }
      this.recordState()
    },

    updateEnterprise(enterprise: Partial<EnterpriseType>) {
      this.enterprise = enterprise
      this.recordState()
    },

    resetEnterprise() {
      this.enterprise = { ...defaultSetting().enterprise }
      this.recordState()
    },

    recordState() {
      ls.set(LOCAL_NAME, this.$state)
    },
  },
})
