import moment from 'moment'
moment.locale('zh-cn')
export function getCurrentDate() {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export function formatDate(origin?: string | number, format = 'YYYY-MM-DD'): string {
  if (!origin)
    return '日期不存在'
  return moment(origin).format(format)
}

interface FixedPointExecuteConfigType {
  isRunNow: boolean
  time: string
}
const detaultValue = {
  time: '00:00:00:000',
  isRunNow: true,
}
export function fixedPointExecute(callBack: Function, config: Partial<FixedPointExecuteConfigType> = detaultValue) {
  if (!callBack || !(callBack instanceof Function))
    return
  const currentDate: any = new Date()
  const targetDate: any = new Date()
  if (config.time) {
    const tempList = config.time.split(':') as [string, string, string, string]
    const timeList = tempList.map(item => parseInt(item)) as [number, number, number, number]
    for (let i = 0; i < 4; i++)
      timeList[i] = timeList[i] ?? 0
    targetDate.setHours(...timeList)
    if (targetDate < currentDate)
      targetDate.setDate(targetDate.getDate() + 1)
  }
  if (config.isRunNow)
    callBack()
  setTimeout(() => {
    callBack()
    config.isRunNow = false
    fixedPointExecute(callBack, config)
  }, targetDate - currentDate)
}

/**
 * 对文件名进行编码
 * @param filename 原始文件名
 * @returns 编码后的文件名
 */
export function encodeFilename(filename: string): string {
  // // 分离文件名和扩展名
  // const dotIndex = filename.lastIndexOf('.')
  // const name = dotIndex !== -1 ? filename.substring(0, dotIndex) : filename
  // const extension = dotIndex !== -1 ? filename.substring(dotIndex) : ''

  // // 对文件名进行编码
  // const encodedName = encodeURIComponent(name)

  // // 返回编码后的完整文件名
  // return encodedName + extension
  return encodeURIComponent(filename)
}
