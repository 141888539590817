<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
const props = defineProps({
  targetTime: {
    type: String,
    default: '',
  },
})
const emits = defineEmits(['endTime'])
const FIX_MINUTES = 60 * 1000
const FIX_HOURS = 60 * FIX_MINUTES
const FIX_DAYS = 24 * FIX_HOURS
// const FIX_YEAR = 365 * FIX_DAYS

const autoMinusId = ref<any>(null)
const computeId = ref<any>(null)
const year = ref(0)
const days = ref(0)
const hours = ref(0)
const minutes = ref(0)
const seconds = ref(0)

const isReturnZero = computed(() => !year.value && !days.value && !hours.value && !minutes.value && !seconds.value)

// 计算两个时间的时间差,返回具体的天\时\分\秒
function computeTime(targetTime: string) {
  const currentDate = new Date()
  const targetDate = new Date(targetTime)
  const time = currentDate.getTime() - targetDate.getTime()

  // year.value = Math.floor(time / FIX_YEAR)
  // const leave0 = time % FIX_YEAR
  days.value = Math.floor(time / FIX_DAYS)
  const leave1 = time % FIX_DAYS
  hours.value = Math.floor(leave1 / FIX_HOURS)
  const leave2 = leave1 % FIX_HOURS
  minutes.value = Math.floor(leave2 / FIX_MINUTES)
  const leave3 = leave2 % FIX_MINUTES
  seconds.value = Math.round(leave3 / 1000)
}
// 基于结果自动减一秒
function autoMinusOneSecond() {
  seconds.value++
  if (seconds.value >= 60) {
    seconds.value = 0
    minutes.value++
  }
  if (minutes.value >= 60) {
    minutes.value = 0
    hours.value++
  }
  if (hours.value >= 24) {
    hours.value = 0
    days.value++
  }
  if (days.value > 365) {
    hours.value = 0
    year.value++
  }
}
// 初始化时间
function initTime() {
  computeTime(props.targetTime)
  if (isReturnZero.value)
    return false
  autoMinusId.value = setInterval(() => {
    autoMinusOneSecond()
    if (isReturnZero.value)
      clearInterval(autoMinusId.value)
  }, 1000)
  return true
}
// 清除定时任务
function clearComputeTime() {
  autoMinusId.value && clearInterval(autoMinusId.value)
  computeId.value && clearInterval(computeId.value)
  autoMinusId.value = null
  computeId.value = null
}
// 开启定时任务
function startComputeTime() {
  if (!props.targetTime)
    return
  clearComputeTime()
  initTime()
  computeId.value = setInterval(() => {
    clearInterval(autoMinusId.value)
    initTime()
    if (isReturnZero.value)
      clearInterval(computeId.value)
  }, 60 * 1000)
}

watch(isReturnZero, () => {
  emits('endTime')
})
watch(() => props.targetTime, () => {
  startComputeTime()
})

onMounted(() => {
  startComputeTime()
})
onBeforeUnmount(() => {
  clearComputeTime()
})
</script>

<template>
  <div class="cmp-fixed-time">
    系统已稳定运行：
    <!-- <span>{{ year }}</span> 年 -->
    <span>{{ days }}</span> 天
    <span>{{ hours }}</span> 时
    <span>{{ minutes }}</span> 分
    <span>{{ seconds }}</span> 秒
  </div>
</template>

<style lang="less" scoped>
.cmp-fixed-time {
  font: normal 400 14px PingFang SC-Regular;
  display: flex;
  align-items: center;
  white-space:nowrap;
  span {
    color: red;
    margin: 0 4px;
  }
}
</style>
