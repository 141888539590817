import service from '@/api/request/service'

export function gptsDiscovery() {
  return service.get<any>('/gpts/public-api/discovery')
}

export function gptsDiscoveryByPage(typeId: string, params: any) {
  return service.get<any>(`/gpts/public-api/discovery/${typeId}`, { params })
}

export function gptsSearch(q: string, signal: any) {
  return service.get<any>(`/gpts/search?q=${q}`, { signal })
}

export function getFavoriteGizmos(data: any) {
  return service.post<any>('/gpts/favorite-gizmos', data)
}

export function putFavoriteGizmos(data: any) {
  return service.put<any>('/gpts/favorite-gizmo', data)
}

export function delFavoriteGizmos(id: any) {
  return service.delete<any>(`gpts/favorite-gizmo/${id}`)
}
