import { defineStore } from 'pinia'
import type { PromptStore } from '@/types/pinia-type'
import { ss } from '@/utils/storage'

const LOCAL_NAME = 'promptStore'
export const usePromptStore = defineStore('prompt-store', {
  state: (): PromptStore => {
    const promptStore: PromptStore | undefined = ss.get(LOCAL_NAME)
    return promptStore ?? { promptList: [] }
  },
  actions: {
    updatePromptList(promptList: []) {
      this.$patch({ promptList })
      ss.set(LOCAL_NAME, { promptList })
    },
    getPromptList() {
      return this.$state
    },
  },
})
