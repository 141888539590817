<script setup lang="ts">
import { reactive, ref } from 'vue'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NCheckbox, NForm, NFormItem, NInput, NSpace } from 'naive-ui'
import { useRouter } from 'vue-router'
import { formRules } from '@/views/login/utils/rule'
import { loginUser } from '@/api/author'
import { useUserStore } from '@/store'
const emits = defineEmits(['updateRouter'])
const userStore = useUserStore()
const router = useRouter()

const formRef = ref<HTMLElement & FormInst>()
const model = reactive({
  rememberMe: true,
  username: '',
  password: '',
})
const rules: FormRules = {
  username: { required: true, message: '用户名不能为空', trigger: 'blur' },
  password: formRules.pwd,
}

const isDisabled = ref(false)
async function handleSubmit() {
  try {
    isDisabled.value = true
    window.$loadingBar?.start()
    await formRef.value?.validate()
    const user = await loginUser(model)
    userStore.setToken(user?.token)
    userStore.handleAndSaveUser(user)
    userStore.setRememberMe(model.rememberMe)
    router.push({ name: 'ordinary-chat' })
    window.$message?.success('登陆成功')
    window.$loadingBar?.finish()
  }
  catch (error: any) {
    window.$loadingBar?.error()
  }
  finally {
    isDisabled.value = false
  }
}
</script>

<template>
  <NForm ref="formRef" :model="model" :rules="rules" size="large" :show-label="false" @keydown.enter="handleSubmit">
    <NFormItem path="username">
      <NInput v-model:value="model.username" placeholder="请输入邮箱" />
    </NFormItem>
    <NFormItem path="password">
      <NInput v-model:value="model.password" type="password" show-password-on="click" placeholder="请输入密码" />
    </NFormItem>
    <NSpace :vertical="true" :size="24">
      <div class="flex items-center justify-between">
        <NCheckbox v-model:checked="model.rememberMe">
          记住我
        </NCheckbox>
        <NButton :text="true" @click="emits('updateRouter', 'reset-pwd')">
          忘记密码？
        </NButton>
      </div>
      <NButton
        type="primary"
        size="large"
        :block="true"
        :round="true"
        :disabled="isDisabled"
        @click="handleSubmit"
      >
        确定
      </NButton>
      <div class="flex items-center justify-between">
        <!-- <NButton class="flex-1" :block="true" @click="emits('updateRouter', 'code-login')">
          手机验证登录
        </NButton> -->
        <!-- <div class="w-[12px]" /> -->
        <NButton class="flex-1" :block="true" @click="emits('updateRouter', 'register')">
          注册
        </NButton>
      </div>
    </NSpace>
  </NForm>
</template>
