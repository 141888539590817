import type { Router } from 'vue-router'
import { useAppStore, useUserStore } from '@/store'
export function setupPageGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    const userStore = useUserStore()
    const appStore = useAppStore()
    window.$loadingBar?.start()
    if (to.name !== 'login' && JSON.stringify(userStore.user) === '{}')
      next({ name: 'login' })
    else if (to.name === 'login' && JSON.stringify(userStore.user) !== '{}')
      next({ name: 'ordinary-chat' })
    else if (to.params && to.params.uuid === '0')
      next({ name: appStore.currentChat })
    else next()
  })
  router.afterEach(() => {
    window.$loadingBar?.finish()
  })
}
