<script lang="ts" setup>
import { reactive, ref, toRefs } from 'vue'
import type { FormInst, FormRules } from 'naive-ui'
import { NButton, NForm, NFormItem, NInput, NSpace } from 'naive-ui'
import { useSmsCode } from '@/views/login/hooks/use-sms-code'
import { formRules, getConfirmPwdRule } from '@/views/login/utils/rule'
import { requestPasswordReset, resetPassword } from '@/api/author'
const emits = defineEmits(['updateRouter'])
const { label, isCounting, loading: smsLoading, start, stop } = useSmsCode()
const formRef = ref<HTMLElement & FormInst>()

const model = reactive({
  email: '',
  code: '',
  pwd: '',
  confirmPwd: '',
})

const rules: FormRules = {
  phone: formRules.email,
  code: formRules.code,
  pwd: formRules.pwd,
  confirmPwd: getConfirmPwdRule(toRefs(model).pwd),
}

async function handleSmsCode() {
  try {
    if (!model.email) {
      window.$message?.warning('请输入电子邮箱!')
      return
    }
    start()
    const message = await requestPasswordReset(model.email)
    window.$message?.success(message ?? '发送成功')
  }
  catch (_error) {
    setTimeout(() => stop(), 1000)
  }
}

async function handleSubmit() {
  await formRef.value?.validate()
  const message = await resetPassword(model.email, model.code, model.pwd)
  window.$dialog?.success({
    title: '密码重置',
    content: `${message ?? '密码重置成功！'}是否立即返回登录页面进行登录。`,
    negativeText: '取消',
    positiveText: '确定',
    onPositiveClick: () => {
      emits('updateRouter', 'pwd-login')
    },
  })
}
</script>

<template>
  <NForm ref="formRef" :model="model" :rules="rules" size="large" :show-label="false">
    <NFormItem path="email">
      <NInput v-model:value="model.email" placeholder="电子邮箱" />
    </NFormItem>
    <NFormItem path="code">
      <div class="flex items-center w-full">
        <NInput v-model:value="model.code" placeholder="验证码" />
        <div class="w-[18px]" />
        <NButton size="large" :disabled="isCounting" :loading="smsLoading" @click="handleSmsCode">
          {{ label }}
        </NButton>
      </div>
    </NFormItem>
    <NFormItem path="pwd">
      <NInput v-model:value="model.pwd" type="password" show-password-on="click" placeholder="密码" />
    </NFormItem>
    <NFormItem path="confirmPwd">
      <NInput v-model:value="model.confirmPwd" type="password" show-password-on="click" placeholder="确认密码" />
    </NFormItem>
    <NSpace :vertical="true" size="large">
      <NButton type="primary" size="large" :block="true" :round="true" @click="handleSubmit">
        确定
      </NButton>
      <NButton size="large" :block="true" :round="true" @click="emits('updateRouter', 'pwd-login')">
        登录
      </NButton>
    </NSpace>
  </NForm>
</template>

<style scoped></style>
