import { defaultSessionModel, modelConfigList } from '@/config/common'

// 获取默认模型
export const defaultModel = (value?: string): string => {
  let resultModel: any = value
  if (value) {
    localStorage.setItem('yhhchat-model', value)
  }
  else {
    resultModel = localStorage.getItem('yhhchat-model')
    if (resultModel) {
      resultModel = modelConfigList.find(item => item.value === resultModel)?.value
      !resultModel && localStorage.setItem('yhhchat-model', '')
    }
  }
  return resultModel || defaultSessionModel
}

// 转换附件列表
export const convertLinkListToChart = (message: string, attachments?: Array<string>) => {
  const attaStr = attachments?.map((item: string) => {
    // 文件名url解码
    const fileName = decodeURIComponent(item.split('/').pop() ?? '')
    return `[已上传：${fileName}]( ${item} )`
  })?.join('\n') ?? ''
  if (attaStr)
    return `${attaStr} \n\n ${message}`
  else
    return message
}

// 递归查询节点列表
export function deepSearchNodes<T>(key: string, nodeList?: T[], deep = 0): { nodes?: T[]; index?: number; deep?: number } {
  if (!nodeList || !key)
    return {}
  deep += 1
  for (let i = 0; i < nodeList.length; ++i) {
    const siblingNode = nodeList[i]
    if ((siblingNode as any).uuid === key)
      return { nodes: nodeList, index: i, deep }
    const { nodes, index, deep: tempDeep } = deepSearchNodes<T>(key, (siblingNode as any).children, deep)
    if (nodes && index !== undefined)
      return { nodes, index, deep: tempDeep }
  }
  return {}
}

// 递归查询目标节点
export function deepSearchNode<T>(key: string, nodeList?: T[]): T | null {
  let siblingNode = null
  if (!nodeList || !key)
    return siblingNode
  for (let i = 0; i < nodeList.length; ++i) {
    const node = nodeList[i]
    if ((node as any).uuid === key)
      return node
    if ((node as any).children) {
      siblingNode = deepSearchNode<T>(key, (node as any).children)
      if (siblingNode)
        return siblingNode
    }
  }
  return siblingNode
}

// 递归处理节点
export function deepHandleNode<T>(nodeList: T[], handle: (his: T) => any | void, childrenName = 'children'): Array<any> {
  const tempList = []
  for (let i = 0; i < nodeList.length; i++) {
    const node: any = Object.assign({}, nodeList[i])
    const tempResult: any = handle && handle(node)
    let children: T[] = []
    if (node[childrenName] && node[childrenName].length)
      children = deepHandleNode(node[childrenName], handle, childrenName)

    if (tempResult) {
      children && (tempResult[childrenName] = children)
      tempList.push(tempResult)
    }
  }
  return tempList
}

// 显示token描述词
export function tokensDisplay(value: number) {
  switch (value) {
    case -2: return '等待计算'
    case -1: return '计算中'
    default: return value
  }
}

// 浏览器刷新
export async function windowFlush(isForce = false) {
  if (isForce) {
    if (window.android && window.android?.forceFlushReload) {
      window.android?.forceFlushReload()
    }
    else {
      const names = await caches?.keys()
      for (const name of names)
        caches.delete(name)
      window.location.reload()
    }
  }
  else {
    window.location.reload()
  }
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export function isAndroid() {
  return /Android/.test(navigator.userAgent)
}
