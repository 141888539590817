import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import { setupPageGuard } from './permission'
import Login from '@/views/login/index.vue'
import Home from '@/views/home/index.vue'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'MainRoot',
    component: () => import('@/components/cmp-layout/main-layout.vue'),
    children: [
      {
        path: 'ordinary-chat/:uuid?',
        name: 'ordinary-chat',
        component: () => import('@/views/chat/index.vue'),
        meta: {
          author: 0b11,
          title: '全能AI',
          icon: 'basil:chat-outline',
        },
      },
      {
        path: 'task-chat/:uuid?',
        name: 'task-chat',
        component: () => import('@/views/chat-task/index.vue'),
        meta: {
          author: 0b11,
          title: '工作台',
          icon: 'icon-park-outline:laptop-computer',
        },
      },
      {
        path: 'manage-page',
        name: 'manage-page',
        redirect: '/manage-page/points-manage',
        component: () => import('@/views/manage-page/index.vue'),
        meta: {
          author: 0b11,
          title: '管理台',
          icon: 'material-symbols:folder-managed-outline',
        },
        children: [
          {
            path: '/manage-page/points-manage',
            name: 'manage-page-points-manage',
            component: () => import('@/views/manage-page/points-manage.vue'),
          },
          {
            path: '/manage-page/member-manage',
            name: 'manage-page-member-manage',
            component: () => import('@/views/manage-page/member-manage.vue'),
          },
          {
            path: '/manage-page/referial-manage',
            name: 'manage-page-referial-manage',
            component: () => import('@/views/manage-page/referial-manage.vue'),
          },
        ],
      },
      {
        path: 'gpts-page',
        name: 'gpts-page',
        component: () => import('@/views/gpts-page/index.vue'),
        meta: {
          author: 0b10,
          title: 'gpts商店',
          icon: 'solar:shop-linear',
        },
      },
      {
        path: 'person-page',
        name: 'person-page',
        component: () => import('@/views/person-page/index.vue'),
        meta: {
          author: 0b01,
          title: '我的',
          icon: 'bi:person',
        },
      },
    ],
  },
  {
    path: '/login/:state?',
    name: 'login',
    component: Login,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/exception/404/index.vue'),
  },

  {
    path: '/500',
    name: '500',
    component: () => import('@/views/exception/500/index.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

setupPageGuard(router)

export async function setupRouter(app: App) {
  app.use(router)
  await router.isReady()
}
