<script setup lang="ts">
import { NConfigProvider, zhCN } from 'naive-ui'
import { onMounted } from 'vue'
import { useUserStore } from './store'
import { NaiveProvider } from '@/components/common'
import { useTheme } from '@/hooks/useTheme'
import { useTextSelectCode } from '@/hooks/functions/useTextSelectCode'
import { useNoticeUpdate } from '@/hooks/functions/useNoticeUpdate'
import { useVersionUpdate } from '@/hooks/functions/useVersionUpdate'
import { useUserInputData } from '@/hooks/functions/useUserInputData'
import { useCommonDialog } from '@/hooks/functions/useCommonDialog'
const userStore = useUserStore()
const { theme, themeOverrides } = useTheme()
useNoticeUpdate()
useVersionUpdate()
useTextSelectCode()
useUserInputData()
useCommonDialog()
onMounted(async () => {
  if (JSON.stringify(userStore.user) !== '{}')
    userStore.requestUser()
})
</script>

<template>
  <NConfigProvider
    class="h-full"
    :theme="theme"
    :theme-overrides="themeOverrides"
    :locale="zhCN"
  >
    <NaiveProvider>
      <RouterView />
    </NaiveProvider>
  </NConfigProvider>
</template>

<style lang="less">
.n-loading-bar-container{
  height: 100vh !important;
  background-color: rgba(0,0,0, 0.15);
  cursor: not-allowed;
}

.n-notification {
  max-width: 90vw !important;
}

.n-modal-container {
  z-index: 6000 !important;
}

html.dark .highlight pre, html.dark pre {
  background-color: #48484e !important;
}
</style>
