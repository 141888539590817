import { h, onBeforeUnmount, onMounted, ref } from 'vue'

import { NButton } from 'naive-ui'
import { formatDate } from '@/utils/functions'
import { useAppStore } from '@/store'
import { getDialogContain } from '@/utils/naive-collect-util'
import { windowFlush } from '@/utils/common'
export function useNoticeUpdate() {
  const appStore = useAppStore()

  // 显示通知信息
  const showNoticeInfo = (html: string, title = '公告', status = 1) => {
    if (!html)
      return
    const noticeContain: any = window.$notification?.create({
      title,
      content: () => h('div', { innerHTML: html }),
      meta: status ? appStore.noticeInfo.updateTime : '',
      action: () => h('div', { class: 'flex' }, status
        ? [
            h(NButton, {
              text: true,
              type: 'primary',
              class: '!mr-5',
              onClick: () => {
                noticeContain?.destroy()
                if (title === '公告')
                  appStore.setNoticeInfo({ isKeepClose: true })
                else if (title === '故障公告')
                  appStore.setFailureNoticeInfo({ isKeepClose: true })
              },
            }, { default: () => '我已知悉' }),
            status === 2
              ? h(NButton, {
                text: true,
                type: 'success',
                onClick: () => {
                  noticeContain?.destroy()
                },
              }, { default: () => '稍后再看' })
              : h('div'),
          ]
        : []),
      onClose: () => {
        noticeContain?.destroy()
      },
    })
  }

  // 获取通知信息
  const isDisabledDialog = ref(false)
  const dialogNotice = async ({ html, timestamp }: any) => {
    const updateTime = formatDate(timestamp, 'YYYY-MM-DD HH:mm:ss')
    appStore.setNoticeInfo({ html, updateTime, timestamp, isKeepClose: false })
    try {
      if (isDisabledDialog.value)
        return
      isDisabledDialog.value = true
      await getDialogContain('发现新的公告', '发现新的公告！（如果点击立即更新后没有更新，请彻底关闭程序重新打开！）', '立即更新', '', false)
    }
    finally {
      isDisabledDialog.value = false
      windowFlush(true)
    }
  }

  // 获取故障通知信息
  const isDisabledFailureDialog = ref(false)
  const failureDialogNotice = async ({ html, timestamp }: any) => {
    const updateTime = formatDate(timestamp, 'YYYY-MM-DD HH:mm:ss')
    appStore.setFailureNoticeInfo({ html, updateTime, timestamp, isKeepClose: false })
    try {
      if (isDisabledFailureDialog.value)
        return
      isDisabledFailureDialog.value = true
      await getDialogContain('发现新的故障公告', '发现新的故障公告！（如果点击立即更新后没有更新，请彻底关闭程序重新打开！）', '立即更新', '', false)
    }
    finally {
      isDisabledFailureDialog.value = false
      windowFlush(true)
    }
  }

  onMounted(() => {
    window.$VueBus.on('submitGlobalNoticeInfo', dialogNotice)
    window.$VueBus.on('submitGlobalFailureNoticeInfo', failureDialogNotice)
    if (appStore.failureNoticeInfo.html !== '')
      showNoticeInfo('系统部分功能故障，在"设置->查看公告"中查看详情', '提示', 0)
    if (!appStore.failureNoticeInfo.isKeepClose && !appStore.isDisableTip)
      showNoticeInfo(appStore.failureNoticeInfo.html, '故障公告', 1)
    if (!appStore.noticeInfo.isKeepClose && !appStore.isDisableTip)
      showNoticeInfo(appStore.noticeInfo.html, '公告', 2)
  })

  onBeforeUnmount(() => {
    window.$VueBus.off('submitGlobalNoticeInfo')
    window.$VueBus.off('submitGlobalFailureNoticeInfo')
  })
}
