<script lang="ts" setup>
import CornerBottom from './components/corner-bottom.vue'
import CornerTop from './components/corner-top.vue'

const lightColor = '#85ccff'
const darkColor = '#096dd9'
</script>

<template>
  <div class="absolute left-0 top-0 z-1 w-full h-full overflow-hidden">
    <div class="absolute -right-[300px] -top-[900px]">
      <CornerTop :start-color="lightColor" :end-color="darkColor" />
    </div>
    <div class="absolute -left-[200px] -bottom-[400px] ">
      <CornerBottom :start-color="darkColor" :end-color="lightColor" />
    </div>
  </div>
</template>

<style scoped></style>
