import service from '@/api/request/service'
import request from '@/api/request/axios'

export function getChatBodyById(id: string) {
  return service.get<{ uuid: string; data: Array<Chat.Chat>; allTokens: number }>(`/user/chat/body/${id}`)
}
export function getChatHistoryList(params = {}) {
  return service.post<{ data: Array<Chat.TaskHistory>; totalCount: number; version: number }>('/user/chat/historys', params)
}

export function updateChatById(id: string, params = {}) {
  return service.patch<any>(`/user/chat/history/${id}`, params)
}

export function saveChatBatch(data = {}) {
  return service.patch<{ unsaveChatHistorys: Array<{ id: string; title: string }> }>('user/chat/task-chat/save', data)
}

export function deleteChatById(id: string) {
  return service.delete<any>(`/user/chat/${id}`)
}

export function clearChatBodyById(id: string) {
  return service.post<any>(`/user/chat/clear/${id}`)
}

export function clearAllChat() {
  return service.delete<any>('/user/chat/all')
}

export function addChatHistory(params: any = {}) {
  return service.post<any>('/user/chat/history', params)
}

export function orderChatHistory(params: any = {}) {
  return service.post<any>('/user/chat/history/task-chat/change-order', params)
}

export function calTokensForAll(uuid: string) {
  return service.get<number>(`/user/chat/body/cal-tokens/${uuid}`)
}

export function exportChatRecord(uuid: string, type: 'markdown' | 'txt') {
  return request(`/user/chat/export/${type}/${uuid}`, {
    responseType: 'blob',
  })
}
