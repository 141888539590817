import { defineStore } from 'pinia'
import type { AppState, Language, NoticeInfoType, Theme } from '@/types/pinia-type'
import { ss } from '@/utils/storage'
import { store } from '@/store'
const LOCAL_NAME = 'appSetting'
const defaultSetting = (): AppState => ({
  // 是否显示左边栏
  siderCollapsed: false,
  // 是否显示右边栏
  siderRightCollapsed: false,
  // 当前主题
  theme: 'light',
  // 当前语言
  language: 'zh-CN',
  // 当前活动的会话
  currentChat: 'ordinary-chat',
  // 是否启用备用线路
  isOpenSpare: false,
  // 是否超额自动计费
  isOverLimitAutoExpense: false,
  // 是否禁用提示
  isDisableTip: false,
  // 是否弹出GPT警告
  isShowTipGPT: true,
  // 是否弹出任务会话会员警告
  isShowTipTask: true,
  // 是否弹出结论模型提示警告
  isShowTipAbstract: true,
  // 是否弹出最大tokens警告
  isShowTipMaxTokens: true,
  // 邀请用户token
  inviteredUser: '',
  // 是否开启自动复制
  isOpenAutoCopy: false,
  isShowCodeRunner: false,
  autoCopyLine: 1200,
  // 是否开启实验模式
  isOpenExperimentalMode: true,
  // 是否启用CDN文件下载加速
  isOpenCDNFileSpeedUp: true,
  // 是否提示开启实验模式
  isTipOpenExperimentalMode: true,
  // 默认结论模式
  defaultConclusionModel: '',
  noticeInfo: {
    html: '',
    timestamp: 0,
    updateTime: '2023-9-24 10:00:00',
    isKeepClose: false,
  },
  failureNoticeInfo: {
    html: '',
    timestamp: 0,
    updateTime: '2023-9-24 10:00:00',
    isKeepClose: false,
  },
})
export const useAppStore = defineStore('app-store', {
  state: (): AppState => {
    const localSetting: AppState | undefined = ss.get(LOCAL_NAME)
    return { ...defaultSetting(), ...localSetting }
  },
  actions: {
    setSiderCollapsed(collapsed: boolean) {
      this.siderCollapsed = collapsed
      this.recordState()
    },
    setSiderRightCollapsed(collapsed: boolean) {
      this.siderRightCollapsed = collapsed
      this.recordState()
    },
    setInviteredUser(userId: string) {
      this.inviteredUser = userId
      this.recordState()
    },

    setTheme(theme: Theme) {
      this.theme = theme
      this.recordState()
    },

    setLanguage(language: Language) {
      if (this.language !== language) {
        this.language = language
        this.recordState()
      }
    },

    setIsOverLimitAutoExpense(flag: boolean) {
      this.isOverLimitAutoExpense = flag
      this.recordState()
    },

    setIsShowCodeRunner(flag: boolean) {
      this.isShowCodeRunner = flag
      this.recordState()
    },

    setIsDisableTip(flag: boolean) {
      this.isDisableTip = flag
      this.recordState()
    },

    setIsShowTipGPT(flag: boolean) {
      this.isShowTipGPT = flag
      this.recordState()
    },

    setIsShowTipTask(flag: boolean) {
      this.isShowTipTask = flag
      this.recordState()
    },

    setIsOpenSpare(flag: boolean) {
      this.isOpenSpare = flag
      this.recordState()
    },

    setIsShowTipAbstract(flag: boolean) {
      this.isShowTipAbstract = flag
      this.recordState()
    },

    setIsShowTipMaxTokens(flag: boolean) {
      this.isShowTipMaxTokens = flag
      this.recordState()
    },

    setCurrentChat(state: string) {
      this.currentChat = state
      this.recordState()
    },

    setIsOpenAutoCopy(flag: boolean) {
      this.isOpenAutoCopy = flag
      this.recordState()
    },

    setAutoCopyLine(count: number) {
      this.autoCopyLine = count
      this.recordState()
    },

    setIsOpenExperimentalMode(flag: boolean) {
      this.isOpenExperimentalMode = flag
      this.recordState()
    },

    setIsOpenCDNFileSpeedUp(flag: boolean) {
      this.isOpenCDNFileSpeedUp = flag
      this.recordState()
    },

    setIsTipOpenExperimentalMode(flag: boolean) {
      this.isTipOpenExperimentalMode = flag
      this.recordState()
    },

    setNoticeInfo(notice: Partial<NoticeInfoType>) {
      this.noticeInfo = Object.assign(this.noticeInfo, notice)
      this.recordState()
    },

    setFailureNoticeInfo(notice: Partial<NoticeInfoType>) {
      this.failureNoticeInfo = Object.assign(this.failureNoticeInfo, notice)
      this.recordState()
    },

    setDefaultConclusionDefault(str: string) {
      this.defaultConclusionModel = str
      this.recordState()
    },

    recordState() {
      ss.set(LOCAL_NAME, this.$state)
    },
  },
})

export function useAppStoreWithOut() {
  return useAppStore(store)
}
