<script setup lang="ts">
import { reactive, ref } from 'vue'
import type { FormInst } from 'naive-ui'
import { NButton, NForm, NFormItem, NInput, NSpace } from 'naive-ui'
import ImageVerify from './image-verify.vue'
import { useSmsCode } from '@/views/login/hooks/use-sms-code'
import { formRules, getImgCodeRule } from '@/views/login/utils/rule'
const emits = defineEmits(['updateRouter'])
const { label, isCounting, loading: smsLoading, getSmsCode } = useSmsCode()

const formRef = ref<HTMLElement & FormInst>()

const model = reactive({
  phone: '',
  code: '',
  imgCode: '',
})

const imgCode = ref('8520')

const rules = {
  phone: formRules.phone,
  code: formRules.code,
  imgCode: getImgCodeRule(imgCode),
}

function handleSmsCode() {
  getSmsCode(model.phone)
}

async function handleSubmit() {
  await formRef.value?.validate()
  window.$message?.success('验证成功!')
}
</script>

<template>
  <NForm ref="formRef" :model="model" :rules="rules" size="large" :show-label="false">
    <NFormItem path="phone">
      <NInput v-model:value="model.phone" placeholder="手机号码" />
    </NFormItem>
    <NFormItem path="code">
      <div class="flex items-center w-full">
        <NInput v-model:value="model.code" placeholder="验证码" />
        <div class="w-[18px]" />
        <NButton size="large" :disabled="isCounting" :loading="smsLoading" @click="handleSmsCode">
          {{ label }}
        </NButton>
      </div>
    </NFormItem>
    <NFormItem path="imgCode">
      <NInput v-model:value="model.imgCode" placeholder="验证码,点击图片刷新" />
      <div class="pl-[8px]">
        <ImageVerify v-model:code="imgCode" />
      </div>
    </NFormItem>
    <NSpace :vertical="true" :size="18">
      <NButton
        type="primary"
        size="large"
        :block="true"
        :round="true"
        @click="handleSubmit"
      >
        确定
      </NButton>
      <NButton size="large" :block="true" :round="true" @click="emits('updateRouter', 'pwd-login')">
        返回
      </NButton>
    </NSpace>
  </NForm>
</template>

<style scoped></style>
