import axios, { type AxiosResponse } from 'axios'
import { router } from '@/router'
import { useAppStore, useUserStore } from '@/store'

const version = import.meta.env.VITE_VERSION
const service = axios.create({
  baseURL: import.meta.env.VITE_GLOB_API_URL,
})
service.defaults.withCredentials = true
service.interceptors.request.use(
  (config) => {
    const token = useUserStore().token
    const noticeTimestamp = useAppStore().noticeInfo?.timestamp ?? ''
    const failureNoticeTimestamp = useAppStore().failureNoticeInfo?.timestamp ?? ''
    config.headers['Web-System-Info'] = JSON.stringify({
      version,
      noticeTimestamp,
      failureNoticeTimestamp,
    })
    token && (config.headers.Authorization = `Bearer ${token}`)
    return config
  },
  error => Promise.reject(error.response),
)

service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response
  },
  (error) => {
    const errorData = error.response?.data ?? {}
    if (error.message === 'Network Error') {
      errorData.message = '网络连接异常'
      window.$message?.warning('网络连接异常')
    }
    if (error.response?.data instanceof Blob) {
      return Promise.reject(error.response.data)
    }
    else if (error.response) {
      const { status } = error.response
      let { message } = error.response.data
      const userStore = useUserStore()

      switch (status) {
        case 302:
          userStore.resetUser()
          router.push({ name: 'login', params: { state: 'pwd-login' } })
          message = message ?? '用户权限异常'
          break
        case 401:
          message = message ?? '权限不足'
          break
        case 505:
          message = null
          break
        case 502:
        case 500:
          message = message ?? '服务器异常'
          break
        case 400:
          message = message ?? '请求异常'
          break
        case 403:
          if (message === '错误：总积分不足！')
            window.$VueBus.emit('dialogIntegralLack')
          break
        case 404:
          message = message ?? '资源不存在'
          break
        case 497:
          window.$VueBus.emit('submitGlobalFailureNoticeInfo', errorData?.data ?? {})
          message = ''
          break
        case 498:
          window.$VueBus.emit('submitGlobalNoticeInfo', errorData?.data ?? {})
          message = ''
          break
        case 499:
          window.$VueBus.emit('submitVersonUpdateTip', errorData?.data ?? {})
          message = ''
          break
      }
      errorData.code = status
      message && window.$message?.warning(message)
    }
    return Promise.reject(errorData)
  },
)

export default service
