import { onBeforeUnmount, onMounted } from 'vue'

import { copyToClip } from '@/utils/copy'
import { getDialogContain } from '@/utils/naive-collect-util'
const cachesInputTextKey = 'session-input-text-key'
export function useUserInputData() {
  // 输入数据
  const addWaitInputCopyText = (data: any) => {
    localStorage.setItem(cachesInputTextKey, data)
  }

  // 拷贝数据
  const copyNextInputText = async () => {
    const data = localStorage.getItem(cachesInputTextKey)
    if (!data)
      return
    try {
      await getDialogContain('通知', '发现上一次的输入数据，是否复制到剪贴板', '复制', '取消')
      copyToClip(data ?? '')
    }
    finally {
      addWaitInputCopyText('')
    }
  }

  onMounted(() => {
    window.$VueBus.on('addWaitInputCopyText', addWaitInputCopyText)
    copyNextInputText()
  })

  onBeforeUnmount(() => {
    window.$VueBus.off('addWaitInputCopyText')
  })
}
