import type { VNode } from 'vue'
import { h } from 'vue'
import { NButton } from 'naive-ui'

export function getDialogContain(title: string, content: string, positiveText = '是', negativeText = '否', isDisClose = true) {
  return new Promise((resolve, reject) => {
    const dialog: any = window.$dialog?.warning({
      title,
      content,
      positiveText,
      negativeText,
      closable: isDisClose,
      closeOnEsc: isDisClose,
      maskClosable: isDisClose,
      onPositiveClick: () => {
        dialog?.destroy()
        resolve(dialog)
      },
      onNegativeClick: (...args) => {
        dialog?.destroy()
        reject(args)
      },
      onMaskClick: (...args) => {
        dialog?.destroy()
        reject(args)
      },
      onClose: (...args) => {
        dialog?.destroy()
        reject(args)
      },
    })
  })
}

export function getDialogContainForTernary(title: string, innerHTML: string | VNode, defaultText = '不再提醒'): Promise<Partial<{ dialog: any; dialogCode: number }>> {
  return new Promise((resolve, reject) => {
    const dialog: any = window.$dialog?.warning({
      title,
      content: () => h('div', {}, [h('div', {}, innerHTML),
        h(NButton, {
          class: '!absolute !mt-[10px] block',
          type: 'info',
          onClick: () => {
            dialog?.destroy()
            resolve({ dialog, dialogCode: 1 })
          },
        }, { default: () => defaultText })]),
      positiveText: '确定',
      negativeText: '取消',
      onPositiveClick: () => {
        dialog?.destroy()
        resolve({ dialog, dialogCode: 0 })
      },
      onNegativeClick: (args) => {
        dialog?.destroy()
        reject(args)
      },
      onMaskClick: (args) => {
        dialog?.destroy()
        reject(args)
      },
      onClose: (...args) => {
        dialog?.destroy()
        reject(args)
      },
    })
  })
}
