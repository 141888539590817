import { onBeforeUnmount, onMounted } from 'vue'

import { useRouter } from 'vue-router'
import { getDialogContain } from '@/utils/naive-collect-util'
import { useAppStore, useUserStore } from '@/store'
import { networkPing } from '@/api/author'

export function useCommonDialog() {
  const appStore = useAppStore()
  const userStore = useUserStore()
  const router = useRouter()

  // 弹出通知信息
  const dialogIntegralLack = async () => {
    if (userStore.isPaidMember)
      return
    await getDialogContain('提示', '您的积分已不足。开通会员，享受不限量使用VIP模型特权！现在就开通吗？也可点击“管理面板”进行积分充值，仅需1元起。', '立即开通', '取消', false)
    router.push({ name: 'manage-page-member-manage' })
  }

  // CDN域名跳转提示
  // const dialogCdnDomainNameJump = async () => {
  //   const hostname = window.location.hostname
  //   if (hostname.startsWith('cdn') || import.meta.env.DEV || window.android || appStore.isDisableTip)
  //     return
  //   await getDialogContain('警告', '您当前访问的域名不是CDN高速服务, 是否立即跳转到CDN高速服务地址？', '立即跳转', '取消', false)
  //   window.location.href = import.meta.env.VITE_CDN_SERVE
  // }

  // 网络异常提示
  const handleNetworkError = async () => {
    try {
      await networkPing()
    }
    catch {
      window.$dialog?.warning({
        title: '网络错误',
        content: '无法与服务器建立连接，请检查您的网络。如有疑问请添加客服微信号“yhhchat-top”获取帮助！',
        positiveText: '',
        negativeText: '',
        closable: false,
        closeOnEsc: false,
        maskClosable: false,
      })
    }
  }

  onMounted(() => {
    window.$VueBus.on('dialogIntegralLack', dialogIntegralLack)
    handleNetworkError()
  })

  onBeforeUnmount(() => {
    window.$VueBus.off('dialogIntegralLack')
  })
}
