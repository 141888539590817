import { defineStore } from 'pinia'
import ObjectID from 'bson-objectid'
import { ss } from '@/utils/storage'
import { convertLinkListToChart, deepHandleNode, defaultModel } from '@/utils/common'
import { router } from '@/router'
import { addChatHistory, clearChatBodyById, deleteChatById, getChatBodyById, getChatHistoryList, updateChatById } from '@/api/chat'
import { fetchChatAPIProcess } from '@/api'
import type { ChatAPIParamsType } from '@/types/pinia-type/index'
import { defaultGptsModel } from '@/config/common'
const openLongReply = import.meta.env.VITE_GLOB_OPEN_LONG_REPLY === 'true'
const JSONParseList = ['uuid', 'title', 'isInit', 'model', 'inputText', 'inputTokens']
const LOCAL_NAME = 'chatStorage'
const defaultHistory = (uuid: string, dModel?: string): Chat.History => ({
  uuid,
  type: 'chat',
  title: 'New Chat',
  isEdit: false,
  model: defaultModel(dModel),
  gizmo: undefined,
  isInit: false,
  isUpdate: false,
  oldSize: '',
})
const defaultState = (): Chat.ChatState => {
  return {
    active: null,
    version: 0,
    loadParams: {
      limit: 20,
      page: 1,
      total: 0,
    },
    history: [],
    chat: [],
  }
}

export const useChatStore = defineStore('chat-store', {
  state: (): Chat.ChatState => {
    const localState = ss.get(LOCAL_NAME)
    return { ...defaultState(), ...localState }
  },

  getters: {
    getChatHistoryByCurrentActive(state: Chat.ChatState) {
      const index = state.history.findIndex(item => item.uuid === state.active)
      if (index !== -1)
        return { ...state.history[index] }
      return null
    },

    getChatByUuid(state: Chat.ChatState) {
      const chatList = state.chat
      return (uuid?: string) => (uuid
        ? chatList.find(item => item.uuid === uuid)?.data
        : chatList.find(item => item.uuid === state.active)?.data
      ) ?? []
    },
  },

  actions: {
    // 设置侧边栏列表参数
    setLoadParams(params: Partial<Chat.ChatParamsType>) {
      this.loadParams = { ...this.loadParams, ...params }
      this.recordState()
    },
    // 获取会话列表
    async getHistoryList(isInit = false) {
      if (isInit)
        this.loadParams.page = 1

      const tempHistory = await getChatHistoryList({ chatType: 'chat', ...this.loadParams })
      const tempList = deepHandleNode(tempHistory.data, (his) => {
        his.oldSize = JSON.stringify(his, JSONParseList) ?? ''
        his.isUpdate = false
        return his
      })

      if (isInit) {
        this.history = tempList
        const uuid = this.history[0]?.uuid
        if (uuid === this.active)
          this.updateAllChatByUuid(uuid)
        this.setActive(uuid)
      }
      else { this.history = [...this.history, ...tempList] }
      this.loadParams.total = tempHistory.totalCount
    },

    // ========================== 聊天记录 ==============================
    async fetchChatAPIOnce({ uuid, gizmo, index, message, selectMode, options, lastText, controller, scrollBottomFunc, attachments, isRegenerate }: ChatAPIParamsType) {
      message = convertLinkListToChart(message, attachments)
      await fetchChatAPIProcess<Chat.ConversationResponse>({
        prompt: message,
        model: selectMode,
        gizmo,
        conversationId: uuid,
        isCloudStorage: true,
        options,
        signal: controller.signal,
        onDownloadProgress: ({ event }) => {
          const xhr = event.target
          const { responseText } = xhr
          const lastIndex = responseText.lastIndexOf('\n', responseText.length - 2)
          let chunk = responseText
          if (lastIndex !== -1)
            chunk = responseText.substring(lastIndex)
          const data = JSON.parse(chunk)
          this.updateChatByUuid(
            uuid,
            index,
            {
              dateTime: new Date().toLocaleString(),
              text: lastText + (data.text ?? (data.message ?? '')),
              inversion: false,
              error: !!data.message,
              gizmo,
              loading: true,
              conversationOptions: { conversationId: data.conversationId, parentMessageId: data.id },
              requestOptions: { prompt: message, options: { ...options } },
            },
          )
          if (openLongReply && data.detail?.choices[0]?.finish_reason === 'length') {
            options.parentMessageId = data.id
            lastText = data.text
            message = ''
            return this.fetchChatAPIOnce({ uuid, index, message, selectMode, options, lastText, controller, scrollBottomFunc, attachments, isRegenerate })
          }

          scrollBottomFunc && scrollBottomFunc()
        },
        isRegenerate,
      })
      this.updateChatSomeByUuid(uuid, index, { loading: false })
      this.chat = [...this.chat]
    },
    // ========================== end 聊天记录 ==============================

    async createHistory(dModel?: string) {
      const uuid = new ObjectID().toString()
      const tempHistory: any = defaultHistory(uuid, dModel)
      tempHistory._id = uuid
      await addChatHistory(tempHistory)
      this.history.unshift(tempHistory)
      this.chat.unshift({ uuid, data: [], allTokens: 0 })
      this.setActive(uuid)
    },

    async createGptsHistory() {
      const gptsString: any = localStorage.getItem('selectGptsInfo')
      localStorage.setItem('selectGptsInfo', '')
      if (!gptsString)
        return
      const gptsInfo: any = JSON.parse(gptsString)
      if (!gptsInfo?.id || !gptsInfo?.name)
        return
      const uuid = new ObjectID().toString()
      const tempHistory = {
        _id: uuid,
        uuid,
        type: 'chat',
        title: gptsInfo.name,
        isEdit: false,
        model: defaultGptsModel,
        gizmo: {
          id: gptsInfo?.id,
          userId: gptsInfo?.userId,
          description: gptsInfo?.description,
          name: gptsInfo?.name,
          profile_picture_url: gptsInfo?.profile_picture_url,
        },
        isInit: false,
        isUpdate: false,
        oldSize: '',
      }
      await addChatHistory(tempHistory)
      this.history.unshift(tempHistory as any)
      this.chat.unshift({ uuid, data: [], allTokens: 0 })
      this.setActive(uuid)
    },

    async updateHistory(uuid: string, edit: Partial<Chat.TaskHistory>) {
      const node = this.history.find(his => his.uuid === uuid)
      if (!node)
        return
      Object.assign(node, edit)
      this.recordState()
      const tempObj = Object.assign({}, edit)
      delete tempObj.isEdit
      if (JSON.stringify(tempObj) !== '{}') {
        await updateChatById(uuid, tempObj)
        this.history = [...this.history]
      }
    },

    async saveHistoryRemote(historyList?: Array<Chat.TaskHistory>) {
      const tempPromiseList: Array<Promise<any>> = []
      const tempHistoryList = historyList || this.history
      tempHistoryList.forEach((his) => {
        if (his.isUpdate) {
          const tempHis = JSON.parse(JSON.stringify(his, JSONParseList))
          tempPromiseList.push(updateChatById(his.uuid, tempHis))
        }
      })
      await Promise.all(tempPromiseList)
      tempHistoryList.forEach((his) => {
        his.oldSize = JSON.stringify(his, JSONParseList) ?? ''
        his.isUpdate = false
      })
      this.recordState()
    },

    async deleteHistory(uuid: string) {
      await deleteChatById(uuid)
      const chatIndex = this.chat.findIndex(itemChat => itemChat.uuid === uuid)
      if (chatIndex !== -1)
        this.chat.splice(chatIndex, 1)
      let hisIndex = this.history.findIndex(his => his.uuid === uuid)
      if (hisIndex !== -1)
        this.history.splice(hisIndex, 1)
      if (Number(hisIndex) >= this.history.length)
        hisIndex = this.history.length - 1
      if (hisIndex === -1)
        hisIndex = 0
      const nextUuid = this.history[hisIndex ?? 0]?.uuid ?? ''
      this.recordState()
      this.setActive(nextUuid)
    },

    async setActive(uuid: string) {
      this.active = uuid
      await this.reloadRoute(uuid)
    },

    getChatByUuidAndIndex(uuid: string, index: number) {
      if (!uuid) {
        if (this.chat.length)
          return this.chat[0].data[index]
        return null
      }
      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1)
        return this.chat[chatIndex].data[index]
      return null
    },

    async createChatByUuid(uuid: string, message: string, options: Chat.ConversationRequest | null, attachments?: Array<any>) {
      message = convertLinkListToChart(message, attachments)
      const newChat: Chat.Chat = {
        dateTime: new Date().toLocaleString(),
        text: message,
        inversion: true,
        error: false,
        conversationOptions: null,
        requestOptions: { prompt: message, options: { ...options } },
      }
      if (options) {
        newChat.inversion = false
        newChat.loading = true
      }

      this.addChatByUuid(uuid, newChat)
    },

    async addChatByUuid(uuid: string, chat: Chat.Chat) {
      if (!uuid)
        return
      let index = this.chat.findIndex(item => item.uuid === uuid)
      if (index === -1) {
        this.chat.unshift({ uuid, data: [], allTokens: 0 })
        index = 0
      }
      this.chat[index].data.push(chat)
      const hisIndex = this.history.findIndex(itme => itme.uuid === uuid)
      if (hisIndex !== -1) {
        let isUpdate = false
        const activeHistory = this.history[hisIndex]
        if (activeHistory.title === 'New Chat') {
          activeHistory.title = chat.text
          isUpdate = true
        }
        if (!activeHistory.isInit) {
          activeHistory.isInit = true
          isUpdate = true
        }
        isUpdate && this.updateHistory(uuid, { isInit: activeHistory.isInit, title: activeHistory.title })
      }
      this.recordState()
    },

    updateChatByUuid(uuid: string, index: number, chat: Chat.Chat) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data[index] = chat
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data[index] = chat
        this.recordState()
      }
    },

    // 移除指定会话某index之后的聊天记录
    removeChatAfterByUuid(uuid: string, index: number) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data.splice(index + 1, this.chat[0].data.length - index)
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data.splice(index + 1, this.chat[chatIndex].data.length - index)
        this.recordState()
      }
    },

    async updateAllChatByUuid(uuid: string) {
      if (!uuid)
        return
      const chatBody = await getChatBodyById(uuid)
      const chatIndex = this.chat.findIndex(c => c.uuid === uuid)
      if (chatIndex !== -1)
        this.chat.splice(chatIndex, 1, chatBody)
      else
        this.chat.push(chatBody)
    },

    updateChatSomeByUuid(uuid: string, index: number, chat: Partial<Chat.Chat>) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data[index] = { ...this.chat[0].data[index], ...chat }
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data[index] = { ...this.chat[chatIndex].data[index], ...chat }
        this.recordState()
      }
    },

    deleteChatByUuid(uuid: string, index: number) {
      if (!uuid) {
        if (this.chat.length) {
          this.chat[0].data.splice(index, 1)
          this.recordState()
        }
        return
      }

      const chatIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (chatIndex !== -1) {
        this.chat[chatIndex].data.splice(index, 1)
        this.recordState()
      }
    },

    // 清空会话
    async clearChatByUuid(uuid: string) {
      if (!uuid) {
        window.$message?.error('会话异常')
        return
      }
      await clearChatBodyById(uuid)
      const charIndex = this.chat.findIndex(item => item.uuid === uuid)
      if (charIndex !== -1) {
        this.chat[charIndex].data = []
        this.recordState()
      }
      window.$message?.success('清除成功')
      this.updateHistory(uuid, { isInit: false })
    },

    async reloadRoute(uuid?: string) {
      this.recordState()
      const activeUuid = router.currentRoute.value.params?.uuid
      const activeName = router.currentRoute.value.name
      if (activeUuid !== uuid && activeName === 'ordinary-chat')
        router.replace({ name: 'ordinary-chat', params: { uuid } })
    },

    recordState() {
      // ss.set(LOCAL_NAME, this.$state)
    },
  },
})
